            <div (click)="click()"   [class.bg-primary] = "product.selected"  [class.text-white] = "product.selected"
              class="
                card
                features
                fea-primary
                rounded
                p-3
                bg-light
                position-relative
                overflow-hidden
                border-0
              "
            >
              <div class="card-body p-0 content">
                <h5 class="float-end">{{product.price}}€</h5>
                <h5>{{product.title}}</h5>
                
                <p class="para text-muted mb-0">
                  {{product.shortDescription}}
                </p>
              </div>
            </div>