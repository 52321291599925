<!-- Hero Start -->
<section class="bg-home d-flex align-items-center"
  style="background: url('assets/images/single/bg01.jpg') center center; height: auto;" id="home">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-9 text-center mt-0 mt-md-5 pt-0 pt-md-5">
        <div class="title-heading margin-top-100">
          <h1 class="display-4 fw-bold mb-3">DJI Professional Drone Camera</h1>
          <p class="para-desc mx-auto text-muted">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap v5 html page.</p>
          <div class="watch-video mt-4 pt-2">
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>

        <div class="home-dashboard">
          <img src="assets/images/single/1.png" alt="" class="img-fluid mover">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Feature Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-6 p-4 text-center">
        <img src="assets/images/icon/camera.svg" height="45" alt="">
        <h5 class="mt-2">Camera</h5>
        <p class="text-muted mb-0">48 mp at 60mtr</p>
      </div>
      <!--end col-->

      <div class="col-md-3 col-6 p-4 text-center">
        <img src="assets/images/icon/speedometer.svg" height="45" alt="">
        <h5 class="mt-2">Speed</h5>
        <p class="text-muted mb-0">50 kph</p>
      </div>
      <!--end col-->

      <div class="col-md-3 col-6 p-4 text-center">
        <img src="assets/images/icon/low-battery.svg" height="45" alt="">
        <h5 class="mt-2">Battery</h5>
        <p class="text-muted mb-0">1 hour and 30 min</p>
      </div>
      <!--end col-->

      <div class="col-md-3 col-6 p-4 text-center">
        <img src="assets/images/icon/game.svg" height="45" alt="">
        <h5 class="mt-2">Control</h5>
        <p class="text-muted mb-0">20 km Range</p>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <img src="assets/images/single/camera.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-3">
          <h4 class="title mb-4">DJI 48MP Camera</h4>
          <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great
            importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the
            distribution of letters visual impact.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 text-primary">Read more <i class="mdi mdi-chevron-right"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Feature End -->

<!-- Description Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <img src="assets/images/single/drone.gif" class="img-fluid" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Awesome Features</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle me-3">
            
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">48mp Camera</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle me-3">
            
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Faster Connection</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle me-3">
            
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Easy to Operate</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle me-3">
            
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Stable Video</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle me-3">
            
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Powerful Antenna</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle me-3">
            
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Battery Backup</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle me-3">
            
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Advance Technology</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle me-3">
            
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Easy Disassembly</h4>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 mt-4 pt-2">
        <div class="d-flex key-feature align-items-center p-3 rounded shadow">
          <div class="icon text-center rounded-circle me-3">
            
          </div>
          <div class="flex-1">
            <h4 class="title mb-0">Rebust Structure</h4>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-md-7">
        <div class="position-relative">
          <img src="assets/images/single/bg02.jpg" class="rounded img-fluid mx-auto d-block" alt="">
          <div class="play-icon">
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="play-btn shadow">
              <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
            </a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Description End -->

<!-- Testimonial Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Client's Feedback</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 pt-2 mt-2 text-center">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">" It seems that only fragments of the oem Ipsum', which is said to
                  have originated in the 16th century. "</p>
                <img src="assets/images/client/01.jpg"
                  class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">" The advantage of its Latin origin andor distract the viewer's
                  attention from the layout. "</p>
                <img src="assets/images/client/02.jpg"
                  class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Carl Oliver <small class="text-muted">P.A</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">" There is now an abundance of readableives to the classic and
                  tell short, funny or nonsensical stories. "</p>
                <img src="assets/images/client/03.jpg"
                  class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Barbara McIntosh <small class="text-muted">M.D</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">" According to most sources, Lorum Ipsuthe origin of the text by
                  of the unusual word he could find "</p>
                <img src="assets/images/client/04.jpg"
                  class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Christa Smith <small class="text-muted">Manager</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">" It seems that only fragments of the oem Ipsum', which is said to
                  have originated in the 16th century. "</p>
                <img src="assets/images/client/05.jpg"
                  class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Dean Tolle <small class="text-muted">Developer</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">" It seems that only fragments of the otime certain letters at
                  various positions within the text. "</p>
                <img src="assets/images/client/06.jpg"
                  class="img-fluid avatar avatar-small mt-3 rounded-circle mx-auto shadow" alt="">
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Jill Webb <small class="text-muted">Designer</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- Partners start -->
  <div class="container mt-5 pt-3">
    <app-clients-logo></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testimonial End -->

<!-- Gallery Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <img src="assets/images/single/01.jpg" />
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <img src="assets/images/single/02.jpg" />
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <img src="assets/images/single/03.jpg" />
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <img src="assets/images/single/04.jpg" />
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <img src="assets/images/single/05.jpg" />
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <img src="assets/images/single/06.jpg" />
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <img src="assets/images/single/07.jpg" />
            </div>
            <!--end customer testi-->
          </ng-template>
        </owl-carousel-o>
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-md-4">
          <h4 class="title mb-4">DJI Spark Drone</h4>
          <div class="d-flex justify-content-between my-4">
            <h4 class="mb-0"><b>$ 1800</b></h4>
            <div class="text-warning">
              <span class="mdi mdi-star"></span>
              <span class="mdi mdi-star"></span>
              <span class="mdi mdi-star"></span>
              <span class="mdi mdi-star"></span>
              <span class="mdi mdi-star-half"></span>
              <span class="text-muted ps-2">23 Reviews</span>
            </div>
          </div>
          <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect. This is required when,
            for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that
            song composers of the past used dummy texts as lyrics when writing melodies in order to have a 'ready-made'
            text to sing with the melody.</p>
          <ul class="list-unstyled text-muted">
            <li><i class="mdi mdi-check me-2"></i>Digital Marketing Solutions for Tomorrow</li>
            <li><i class="mdi mdi-check me-2"></i>Create your own skin to match your brand</li>
            <li><i class="mdi mdi-check me-2"></i>Digital Marketing Solutions for Tomorrow</li>
            <li><i class="mdi mdi-check me-2"></i>Create your own skin to match your brand</li>
          </ul>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary">Buy Now</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Sign up for our Newsletter</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center mt-4 pt-2">
      <div class="col-lg-7 col-md-10">
        <div class="text-center subcribe-form mt-4 pt-2">
          <form>
            <input name="email" id="email" type="email" class="form-control rounded-pill border"
              placeholder="Your email :" required aria-describedby="newssubscribebtn">
            <button class="btn btn-pills btn-primary submitBnt" type="submit" id="newssubscribebtn">Subscribe</button>
          </form>
          <!--end form-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--END container-->
</section>
<!--end section-->
<!-- Gallery End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  
</a>
<!-- Back to top -->