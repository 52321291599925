import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/services/product';

@Component({
  selector: 'app-pricecard',
  templateUrl: './pricecard.component.html',
  styleUrls: ['./pricecard.component.css']
})
export class PricecardComponent implements OnInit {

  @Input() product: Product

  @Input() updateSignal: Function

  constructor() { }

  ngOnInit(): void {
  }

  click(){
    console.log("hello!")
    if(!this.product.selected){
      this.product.selected = true;
    }
    this.updateSignal(this.product)
  }
}
