<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/index" class="btn btn-icon btn-primary">
    
  </a>
</div>

<!-- COMING SOON PAGE -->
<section class="bg-home d-flex align-items-center" data-jarallax='{"speed": 0.5}'
  style="background-image: url('assets/images/comingsoon.jpg');">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 text-center">
        <a href="javascript:void(0)" class="logo h5"><img src="assets/images/logo-light.png" height="24" alt=""></a>
        <div class="text-uppercase title-dark text-white mt-2 mb-4 coming-soon">We're Coming soon...</div>
        <p class="text-light para-desc para-dark mx-auto">Start working with <span
            class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
          awareness, drive traffic, connect.</p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <div id="countdown">
          <ul class="count-down list-unstyled">
            <li id="days" class="count-number list-inline-item m-2">{{_days}}<p class="count-head">Days</p>
            </li>
            <li id="hours" class="count-number list-inline-item m-2">{{_hours}}<p class="count-head">Hours</p>
            </li>
            <li id="mins" class="count-number list-inline-item m-2">{{_minutes}}<p class="count-head">Minutes</p>
            </li>
            <li id="secs" class="count-number list-inline-item m-2">{{_seconds}}<p class="count-head">Seconds</p>
            </li>
            <li id="end" class="h1"></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <a routerLink="/index" class="btn btn-primary mt-4"><i class="mdi mdi-backup-restore"></i> Go Back Home</a>
      </div>
    </div>
  </div> <!-- end container -->
</section>
<!--section end-->
<!-- COMING SOON PAGE -->