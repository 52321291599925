<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
  <div class="container">
    <div class="row align-items-center pt-5">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-3">Grap your own<br> Dedicated<br> OpenSearch</h1>
          <p class="para-desc text-muted">Launch your OpenSearch and OpenSearch dashboard solution within minutes. From only 39$ per month.</p>
          <div class="mt-4 pt-2">
            <a  routerLink="/order" class="btn btn-primary">Start your 7 days trial</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative">
          <div class="bg-saas-shape position-relative">
            <img src="assets/images/own/laptop.png" class="mx-auto d-block" alt="">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Section Start -->
<section class="section overflow-hidden ">
  <div class="container pb-5 mb-md-5">
    <app-services [servicesData]="servicesData"></app-services>
    <!--end row-->
  </div>
  <!--end container-->

  <div id="pricing-table" class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Pricing Rates</h4>
          <p class="text-muted para-desc mb-0 mx-auto">All our Servers are based on latest technology and is storage is baked by Ceph to ensure high availability even on single node setups.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-pricing [pricingData]="pricingData"></app-pricing>
  </div>
  <!--end container-->



  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">Why Choose us ?</h1>
          <p class="para-desc text-muted">Nobody wants the hassle of running there own logging platform. So we made it easy without loosing flexibility. <br/>When your service is provisioned you can be king in your own castle. And we will do the house cleaning.</p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  
                </h2>
                <div class="ms-3">
                  <h5>Fully Secured</h5>
                  <p class="text-muted mb-0">We provide out of the box security including free SSL certificate and private virtualized network for your servers.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  
                </h2>
                <div class="ms-3">
                  <h5>Best Performance</h5>
                  <p class="text-muted mb-0">Speed is essential for Opensearch. Thats why all our plans comes with newest hardware and plenty of cpu cores and memory.</p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 pt-4">
              <a href="javascript:void(0)" class="btn btn-outline-primary">Install Now <i
                  class="uil uil-angle-right-b"></i></a>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/opensearch.svg" class="img-fluid mx-auto d-block rounded " alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->




<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  
</a>
<!-- Back to top -->