<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-7">
        <div class="title-heading mt-4">
          <h1 class="heading mb-3">Your service is getting started...</h1>
          <p class="para-desc text-muted">It should be ready within minutes</p>
          <div class="mt-4 pt-2">

          <ul class="list-unstyled">
            <li *ngFor="let process of processes" class="h6 text-muted mb-0">
              <span class=" text-primary h5 me-2"><i [class.icon-advanced]="!process.completed" [class.uil-check-circle]="process.completed" class="text-success uil  align-middle"></i></span>{{process.description}}
            </li>
          </ul>

          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <img src="assets/images/illustrator/services.svg" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  
</a>
<!-- Back to top -->