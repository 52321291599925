<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">Documentation</h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">TATIC.IO</a></li>
                <li class="breadcrumb-item active" aria-current="page">
                  Documentation
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
        fill="currentColor"
      ></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-12 d-none d-md-block">
        <div class="sticky-bar component-wrapper bg-light p-4">
          <ul class="list-unstyled mb-0">
            <div class="div" *ngFor="let rootItem of entries; let i = index">
              <li>
                <a
                  routerLink="/documentation/{{ rootItem.fields.path }}"
                  [class.text-primary]="
                    rootItem.fields.path === getActivatedRoute()
                  "
                  class="mouse-down h6 text-dark"
                  >{{ i + 1 }}. {{ rootItem.fields.title }}</a
                >
              </li>
              <div style="margin-left: 10px">
                <li *ngFor="let child of rootItem.fields.childs; let i = index">
                  <a
                    routerLink="/documentation/{{ rootItem.fields.path }}"
                    [queryParams]="{ id: child.fields.path }"
                    [ngxScrollTo]="child.fields.path"
                    class="mouse-down h6 text-dark"
                    >- {{ child.fields.title }}</a
                  >
                </li>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <div class="col-lg-9 col-md-8 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div *ngIf="activeArticle != null" class="row row-cols-1 ms-lg-2">
          <!-- Typography Heading Start -->
          <div class="col" id="typography">
            <div class="component-wrapper">
              <div class="p-4 border-bottom">
                <h1 class="title mb-0">{{ activeArticle.fields.title }}</h1>
              </div>

              <div [innerHTML]="activeArticle.fields.body | marked"></div>

              <div
                *ngFor="let child of activeArticle.fields.childs; let i = index"
              >
                <h2 id="{{ child.fields.path }}" class="title mb-0">
                  {{ child.fields.title }}
                </h2>
                <div [innerHTML]="child.fields.body | marked"></div>
              </div>
            </div>
          </div>
          <!--end col-->
          <!-- Typography Heading End -->
        </div>
        <div *ngIf="activeArticle == null" class="row row-cols-1 ms-lg-2">
          <!-- Typography Heading Start -->
          <div class="col" id="typography">
            <div class="component-wrapper rounded shadow">
              <div class="p-4 border-bottom">
                <h1 class="title mb-0">Welcome to the documentation section</h1>
              </div>

              <div>
                <p>this is the start</p>
              </div>
            </div>
          </div>
          <!--end col-->
          <!-- Typography Heading End -->
        </div>
        <!--end row-->
      </div>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  
>
  
</a>
<!-- Back to top -->
