<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Team Members </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item active" aria-current="page">Team</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Team Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title">Team #1</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-member [memberData]="memberData"></app-member>

    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title">Team #2</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/05.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Krista John</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/06.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Jack John</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/01.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Roger Jackson</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/02.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Luchhi Cina</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/03.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Sophiya Cally</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/04.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Johnny English</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Team Start -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  
</a>
<!-- Back to top -->