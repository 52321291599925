import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Product } from "src/app/services/product";
import { ProductsService } from "src/app/services/products.service";
import { UserContextService } from "src/app/services/usercontext.service";
import { Country } from "src/app/services/country";
import OrderService from "src/app/services/order.service";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.css"],
})
export class OrderComponent implements OnInit {
  footerClass: true;
  footerVariant = "bg-light";
  products: Product[];
  serviceName: string = ""
  email: string = ""
  name: string = ""
  countries: Country[]
  productSelected: boolean = false;
  SelectedProduct: Product;
  loading: boolean = false;
  constructor(private productService: ProductsService, userContextService: UserContextService, private orderService: OrderService) {
    userContextService.countryList.subscribe(x=> this.countries = x);
  }

  ngOnInit(): void {
    this.productService.getProducts().subscribe((x) => {
      this.products = x;
      console.log(this.products);
    });
  }

  updateSelection = (product: Product) => {
    this.productSelected = true;
    this.SelectedProduct = product;
    for (let index = 0; index < this.products.length; index++) {
      const element = this.products[index];
      if(element != product){
        element.selected = false
      }
    }
  }

  

submitOrder = async () => {
    this.loading = true;
    try {
      var submittedOrder = await this.orderService.SubmitOrder({
        customer: { companyName: this.name,
        email: this.email},
        orderLines: [{productId: this.SelectedProduct.internalProductId}],
        serviceName: this.serviceName
      }).toPromise();
      window.location.href = submittedOrder.url
      this.loading = false;
    } catch (error) {
      this.loading = false;    }

  }
}
