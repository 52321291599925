import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { UserContext } from "./user-context";
import { Observable, Subject } from "rxjs";
import EnvironmentService from "./environment.service";
import { Country } from "./country";

@Injectable({
  providedIn: "root",
})
export class UserContextService {
  private subject = new Subject<UserContext>();
  public userContext: Observable<UserContext>;
  countryList: Observable<Country[]>;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService
  ) {
    this.userContext = this.subject.asObservable();
    http
      .get<UserContext>(`${environmentService.apiBasePath}/profile `, {
        withCredentials: true,
      })
      .subscribe((x) => this.subject.next(x));

    this.countryList = http.get<Country[]>(
      `${environmentService.apiBasePath}/countries `,
      {
        withCredentials: true,
      }
    );
  }

  public async Logout() {
    await this.http
      .get(`${this.environmentService.apiBasePath}/logout`, {
        withCredentials: true,
      })
      .toPromise();
    this.subject.next(null);
  }

  public async login() {
    window.location.href = `${this.environmentService.apiBasePath}/login?returnUrl=${window.location}`;
  }
}
