<section
  class="bg-home d-flex align-items-center"
  style="
    background: url('assets/images/saas/home-shape.png') center center;
    height: auto;
  "
  id="home"
>
  <div class="container">
    <div class="title-heading margin-top-100">
      <h1 class="heading mb-3">Select your subscription</h1>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 mt-0 mt-md-5 pt-0 pt-md-5">
        <div class="row">
          <div class="col-md-3 mb-3" *ngFor="let product of products">
            <app-pricecard
              [product]="product"
              [updateSignal]="updateSelection"
            ></app-pricecard>
          </div>
        </div>
      </div>
      <div [hidden]="!productSelected"  class="col-12 mt-0 mt-md-5 pt-0 pt-md-5">
        <div
          _ngcontent-gdw-c262=""
          class="card work-process border-0 rounded shadow"
        >
          <div _ngcontent-gdw-c262="" class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h4 class="title">Your service name</h4>
                <input
                  name="name"
                  id="name"
                  type="text"
                  (keyup)="serviceName = $event.target.value"
                  [value]="serviceName"
                  placeholder="MyService"
                  class="form-control"
                  autocomplete="disabled"
                />
                <p class="text-muted para">
                  OpenSearch url:
                  <strong>opensearch-{{ serviceName }}.tatic.io</strong>
                  <br />
                  OpenSearch Dashboard:
                  <strong>{{ serviceName }}.tatic.io</strong>
                </p>
              </div>
              <div class="col-md-6">
                <h4 class="title">Billing details</h4>
                <input
                  name="email"
                  id="email"
                  type="text"
                  (keyup)="email = $event.target.value"
                  [value]="email"
                  placeholder="Email"
                  class="form-control "
                />
                <input
                  name="name"
                  id="name"
                  type="text"
                  (keyup)="name = $event.target.value"
                  [value]="name"
                  placeholder="Company Name"
                  class="form-control  mt-3"
                  autocomplete="disabled"
                />
                <select
                  name="country"
                  class="form-select form-control mt-3"
                  id="country"
                >
                  <option
                    value="{{ country.countryCode }}"
                    [attr.selected]="country.isDefault ? true : null"
                    *ngFor="let country of countries"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <div class="row">
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-6 text-end">
                    <button class="btn btn-success mt-3" [disabled]="loading" (click)="submitOrder()">
                      Start my 7 days trial
                    </button>
                    <p class="text-muted">*you can cancel anytime</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="home-dashboard">
        <img src="assets/images/own/laptop.png" alt="" class="img-fluid" />
      </div>

      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
