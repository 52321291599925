import { Injectable } from "@angular/core";
import { createClient, Entry, EntryCollection } from "contentful";
import { from, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

const CONFIG = {
  space: "vaekd8eb1yy8",
  accessToken: "IMWcyLb4qtMYdCmOcWingPd_s4OZxzEeZxzm_fDPTtg",

  contentTypeIds: {
    article: "article",
  },
};

@Injectable()
export class ContentfulService {
  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken,
  });
  private entries: Entry<any>[];

  constructor() {}

  async getArticles(query?: object): Promise<Entry<Article>[]> {
    let res = await this.cdaClient.getEntries<Article>(
      Object.assign(
        {
          content_type: CONFIG.contentTypeIds.article,
        },
        query
      )
    );
    let items =  res.items.sort((x,y)=> (x.fields.index > y.fields.index) ? 1 : -1);
    items.forEach(x=> x.fields.childs = [])

    var rootArticles = items.filter((x) => !x.fields.parent);
    var childArticles = items.filter((x) => x.fields.parent);
    childArticles.forEach(
      (x) =>
        (rootArticles.find(
          (y) => y.sys.id == x.fields.parent.sys.id
        ).fields.childs.push(x))
    );
    this.entries = rootArticles;
    console.log(this.entries);

    return rootArticles;
  }
}

export interface Article {
  path: string;
  body: string;

  index: number;

  parent: Entry<Article>; 
  childs: Entry<Article>[];
}
