<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Terms of Services </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">TATIC.IO</a></li>
                <li class="breadcrumb-item active" aria-current="page">Terms of Services</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <h4>INTRODUCTION</h4>
            <p>The following Terms and Conditions apply to all orders based on relevance and scope and in the order of precedence listed below:</p>
            <ul>
              <li>Terms and conditions</li>
              <li>Legal notice</li>
            </ul>
            <h4>Terms and conditions</h4>
            <h5>1. GENERAL - SCOPE OF APPLICATION            </h5>

            <p>1.1 The following Terms and Conditions apply to all business relationships between the Customer and Tatic ApS, also hereinafter referred to as "us/we". These Terms and Conditions are an integral part of all contracts with the Customer. These Terms and Conditions also apply to future services and offers, even if the Customer does not separately agree upon them again.</p>
<p>1.2. The terms and conditions of our customers or third parties are not applicable and are not part of the contract, even if we do not separately object to their application in individual cases.</p>
<p>1.3 We expressly reserve the right to make changes to our Terms and Conditions, System Policies and prices by giving the Customer prior notification via their customer account or using the email address the Customer enters in their contact information.</p>
<p>1.4. The conditions and policies listed in the preamble apply in the order of precedence listed above.</p>
<p>1.5. Our employees and third parties commissioned by them are not authorized to make verbal agreements or to provide any verbal guarantees or commitments to the Customer.</p>
<h5>2. CONCLUSION, DURATION AND TERMINATION OF THE CONTRACT</h5>
<p>2.1. The contract is concluded when the Customer submits their order and we accept their order in accordance to the provisions of point 2.4 of these Terms and Conditions.</p>
<p>2.2. Our offers are subject to change and are non-binding. We reserve the right to make technical and other changes within the scope of what is reasonable.</p>
<p>2.3. The Customer declares that the data they provided on their customer account are correct and complete and shall notify us of any changes in this regard in writing within no more than 14 days. The Customer is required to provide evidence of the correctness of the data upon request.</p>
<p>2.4. The Customer enters into a binding contract by placing the order and by accepting these Terms and Conditions. We are entitled to accept or reject the contract in the Customer’s order within a period of 5 work days after we receive the order. By merely confirming that we have received the Customer’s order, we are not bound to accept their order and the contract it contains.</p>
<p>2.5. Contracts are concluded for an unlimited time period unless otherwise agreed.</p>
<p>2.6. The contract may be terminated by either party with 30 days' notice to the end of the month, without specifying any reasons. Differing periods of notice may apply to the Customers depending on the description of relevant services. Notice of termination may be given in text form by email, chat or via the Customer’s account on our secure customer interface.</p>
<p>2.7. Furthermore, we reserve the right to terminate the contractual relationship without notice for good cause. Such good cause is deemed to exist, among other reasons, if the Customer fails to meet its payment obligations or violates other important customer obligations. A further important reason which may result in us locking or terminating the Customer’s services or account without notice is if the Customer uses content that impairs the regular operating behavior or the security of our infrastructure or our product, or violates paragraphs 8.1. - 8.3. of these Terms and Conditions.</p>
<h5>3. SCOPE OF SERVICES</h5>
<p>3.1. The scope of the contractual service is based on the product description that is valid at the time of the Customer’s order and the written agreements resulting from it. We reserve the right, after prior notice, to discontinue services we offer free of charge or to introduce fees for these services.</p>
<p>3.2. If the subject of the contractual relationship is the registration of domain names, we only are obligated to arrange the desired domain registration. We do not accept liability for or provide any warranty that the domain registration authorities will actually allocate the domain name that the Customer requests in their order. The Customer is only entitled to assume they will actually be allocated the domain name once we have confirmed the allocation. We have no influence on the domain allocation.</p>
<p>3.3. We undertake to make economically reasonable efforts to achieve an annual average service availability of 99.9% at our data centers.</p>
<p>3.4 We strive to provide the best possible migration path during onboarding but we do not guarantee that we can accommodate all needs.</p>
<h5>4. PAYMENT CONDITIONS AND LATE PAYMENTS</h5>
<p>We will invoice the Customer for any contractually binding services using the updated prices visible on www.tatic.io, plus the statutory value added tax.</p>
<p>4.2. Depending on the contractual agreement, we process monthly, quarterly or annual invoices using the agreed means of payment. The Customer is obligated to comply with the terms and conditions of the payment service they use to pay their invoice.</p>
<p>4.3. If we permit the Customer to make a late payment, we maintain the right, even without a reminder, to charge interest for the late payment beginning on the due date.</p>
<p>4.5. The Customer is obligated to comply with any European applicable export and import control regulation.</p>
<h5>5. ADMINISTRATOR RIGHTS AND DUTIES / DATA SECURITY</h5>
<p>5.1. The customer has limited access to the underlying virtual or physical server provided.</p>
<p>5.2. All services will be provided with an administrative access right which ensures the customer has full access to the given service offered.</p>
<p>5.3. The customer has the responsibility to keep the system maintainable for Tatic.io</p>
<p>5.4. The Customer, by using our services, is obligated to set up and manage their servers in such a way that does not compromise the integrity and availability of the networks, servers and data of third parties. In particular, it is strictly forbidden to use the servers for (d)DOS attacks or to run open mail relays or other systems that are capable of performing these actions. If the Customer violates this agreement, we reserve the right - without prior notice - to lock the server and terminate the contract without notice.</p>
<p>5.5. The Customer is obligated to use the services provided appropriately and to refrain from abusive and illegal actions.</p>
<p>5.6. The Customer is responsible for making regular backups (backup copies) of their data; the backups should be stored outside the services provided by us. If data is transmitted to us on the Customer’s servers, the Customer is obligated to make regular backup copies of the data. The customer is obligated to perform a complete data backup prior to any change they make on their own behalf or on the behalf of a third party. If there is nonetheless a loss of data, the Customer is obligated to transfer the relevant data files to us again free of charge or to restore the data themselves.</p>
<p>5.7. Tatic.io will provided on server level backup every 24 hours and keep the backup available in 7 days. We do not guarantee consistency nor we do guarantee the backup data will be usable.</p>
<p>5.8. YOU ACKNOWLEDGE THAT YOU BEAR SOLE RESPONSIBILITY FOR THE ADEQUATE SECURITY, PROTECTION, AND INTEGRITY OF YOUR CONTENT. <br/><br/></p>
<h5>6. USE BY THIRD PARTIES</h5>
<p>6.1. The Customer is entitled to grant third parties a contractual term of use to any services the Customer orders from Tatic.io. In this case, the Customer nevertheless remains the sole contractual partner. The Customer continues to be solely and fully liable for compliance with the contractual agreements between us and the Customer.</p>
<p>6.2. If the Customer transfers user rights to their Tatic.io services to a third party, the Customer is obligated at the time of transfer to ensure that all legal and contractual provisions are followed. This is true for any changes that require the cooperation of the third party.</p>
<p>6.3. If the third party violates the contractual obligations or does not comply with the obligation to cooperate, if the data provided by the third party is incorrect or incomplete, or if other problems arise with the granting of user rights to third parties, the Customer assumes full liability for all resulting damages and, in addition, indemnifies us from all claims made against us by the third party or others.</p>
<h5>7. USE OF THE SERVICES / CONTENT</h5>
<p>7.1. The Customer is obligated to check and comply with the legal provisions arising from the use of the contractually agreed services, in particular the Telecommunications Act, the Telemedia Act, as well as national and international industrial and intellectual property rights, personal rights, and the requirements of competition and data protection laws on their own. The Customer indemnifies us against all claims of third parties arising from infringements of these obligations.</p>
<p>7.2. The Customer is obligated not to publish any content that infringes on the rights of third parties or otherwise violates applicable law. This includes in particular, but is not limited to, pornographic or obscene material, extremist content or content that offends common decency, gambling, material that could seriously endanger the morals of children or young people or violate the rights of third parties (copyrights, name rights, trademark rights and data protection rights). This also includes the publication of defamatory content, insults or disparagement of persons or groups of persons.</p>
<p>7.3. The transmission of spam mail is prohibited. This includes in particular the sending of unauthorized, unsolicited advertising to third parties. When sending emails, it is also prohibited to provide false sender data or to disguise the identity of the sender in any other way. The operation of applications for mining cryptocurrencies remains prohibited. These include, but are not limited to, mining, farming and plotting of cryptocurrencies. We are entitled to lock the Customer’s access to their Tatic.io services or account in the event of non-compliance.</p>
<h5>8. LIABILITY</h5> 
<p>8.1. The Customer uses Tatic.io services at their own risk. We are liable for indirect damages in the case of intent or gross negligence, but not for loss of profit. We are liable for culpable infringements that are not due to gross negligence or intent for the foreseeable damage typical for this type of contract, up to a maximum of 100 % of the price for the Customer's monthly product rental.</p>
<p>If the Customer violates the content obligations mentioned in Section 8 of these Terms and Conditions, in particular regarding legal prohibitions and decency violations, the Customer is liable to us for compensation for all direct or indirect damages arising from this, including financial losses. Furthermore, the Customer is obligated to indemnify us against claims by third parties - irrespective of the legal basis - resulting from the Customer or their designated third parties. The indemnification obligation also includes all incurred legal defense costs.</p>
<h5>9. CANCELLATION POLICY</h5>
<p>9.1. The Customer has the right to cancel this contract within fourteen days after the conclusion of the contract without providing any reasons. To exercise their right to cancel this contract, the Customer is required to notify us,  Tatic ApS, Mejsevej 2, DK-8370 Hadsten, Denmark, phone: +45 70 20 60 85, email: info@tatic.io by means of a clear declaration (e.g. a letter sent by post, fax, email or via the Customer’s account on our secure online administration interface) of the Customer’s decision to cancel this contract. The Customer is free to use the cancellation template for this purpose, although it is not mandatory. To meet the cancellation requirements, it is sufficient for the Customer to send a clearly worded notification that they wish to exercise their right of cancellation before the cancellation deadline.</p>
<p>9.2. Cancellation procedure If the Customer cancels this contract, we will immediately or at the latest fourteen days from the date of our receiving the notice of cancellation of this contract, refund all payments we have received from the Customer including delivery charges (with the exception of additional charges arising from the Customer choosing a type of delivery other than our standard low-cost standard delivery). This refund will use the same means of payment that the Customer used in the original transaction, unless we mutually agree otherwise. Under no circumstances is the Customer charged any fees for this refund.</p>
<p>9.3 If the cancelled item is a service that has already begun at the time of cancellation, we will invoice the Customer for the corresponding pro rata amount.</p>
<p>9.4 We strive to provide the best possible solution to accommodate any cancellation requests. Please use the Self-service customer portal on tatic.io.</p>
<h4>Legal Notice</h4>
<p>All services by Tatic.io is provided by <br>Tatic.ApS<br/>Mejsevej 2<br/>DK-8370 Hadsten<br/>Denmark<br/>phone: +45 70 20 60 85<br/>email: info@tatic.io</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  
</a>
<!-- Back to top -->