<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-7">
        <div class="title-heading mt-4">
          <div class="alert alert-transparent alert-pills shadow" role="alert">
            <span class="badge rounded-pill bg-primary me-2">New</span>
            <span class="content">New Added Modern and Classic App Showcase</span>
          </div>
          <h1 class="heading mb-3">We build best apps that users love</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>
          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-primary m-1 me-2">Install Now</a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0)" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-app-image position-relative">
          <div class="bg-app-shape position-relative">
            <img src="assets/images/app/classic01.png" class="img-fluid mover mx-auto d-block " alt="">
          </div>
          <div class="app-images d-none d-md-block">
            <img src="assets/images/app/calender.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Section Start -->
<section class="section overflow-hidden">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <span class="badge bg-primary rounded-pill mb-2">Features</span>
          <h4 class="title mb-4">What We Do ?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 col-12 mt-4 pt-2">
        <div class="card text-center rounded border-0">
          <div class="card-body">
            <div class="p-3 bg-light rounded shadow d-inline-block">
              <img src="assets/images/icon/art-and-design.svg" class="avatar avatar-small" alt="">
            </div>
            <div class="mt-4">
              <h5><a href="javascript:void(0)" class="text-dark">Design & Branding</a></h5>
              <p class="text-muted mt-3 mb-0">If the distribution of letters and distribution 'words' is random, the
                reader will not be distracted.</p>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-4 pt-2">
        <div class="card text-center bg-primary bg-gradient rounded border-0">
          <div class="card-body">
            <div class="p-3 bg-light rounded shadow d-inline-block">
              <img src="assets/images/icon/smartphone.svg" class="avatar avatar-small" alt="">
            </div>
            <div class="mt-4">
              <h5><a href="javascript:void(0)" class="text-white title-dark">Fully Secured</a></h5>
              <p class="text-white-50 mt-3 mb-0">If the distribution of letters and distribution 'words' is random, the
                reader will not be distracted.</p>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-4 pt-2">
        <div class="card text-center rounded border-0">
          <div class="card-body">
            <div class="p-3 bg-light rounded shadow d-inline-block">
              <img src="assets/images/icon/clock.svg" class="avatar avatar-small" alt="">
            </div>
            <div class="mt-4">
              <h5><a href="javascript:void(0)" class="text-dark">High Performance</a></h5>
              <p class="text-muted mt-3 mb-0">If the distribution of letters and distribution 'words' is random, the
                reader will not be distracted.</p>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-5">
        <div class="app-feature-shape-left position-relative">
          <div class="text-center text-md-start">
            <img src="assets/images/app/classic02.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">
        <div class="section-title">
          <h1 class="title mb-3">We Build High Performing <br> Application</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <div class="mt-4">
            <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                class="uil uil-angle-right-b"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
        <div class="section-title">
          <h1 class="title mb-3">Easy And Best Solution <br> For Your App</h1>
          <p class="para-desc text-muted">Launch your Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eveniet
            eligendi expedita ducimus fuga sed possimus veritatis eum voluptates. Ab ex odio sed atque. Quam delectus,
            voluptatibus rem harum nihil minus. campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary">Learn More <i class="uil uil-angle-right-b"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 order-1 order-md-2">
        <div class="app-feature-shape-right position-relative">
          <div class="text-center text-md-end">
            <img src="assets/images/app/classic03.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-5">
        <div class="app-feature-shape-left position-relative">
          <div class="text-center text-md-start">
            <img src="assets/images/app/classic04.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">
        <div class="section-title">
          <h1 class="title mb-3">Beautiful, Simple & <br> Easy to Use</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary">Read More <i class="uil uil-angle-right-b"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60 pb-md-5 mb-md-5">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <span class="badge rounded-pill bg-success mb-2">Reviews</span>
          <h4 class="title mb-4">What our users says !</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!---end container-->
</section>
<!--end section-->
<!-- Section End -->

<!-- Subscribe Start -->
<section class="section bg-primary bg-gradient">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container position-relative">
    <div class="row">
      <div class="col-lg-5 col-md-6">
        <div class="app-subscribe text-center text-md-start">
          <img src="assets/images/app/classic05.png" class="img-fluid" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title text-center text-md-start">
          <h1 class="title text-white title-dark mb-2">Subscribe to our weekly news</h1>
          <p class="text-light para-dark">Sign up and receive the latest tips via email.</p>
        </div>
        <div class="subcribe-form mt-4 pt-2">
          <form class="ms-0">
            <div class="mb-2">
              <input type="email" id="email" name="email" class="rounded-pill" placeholder="E-mail :">
              <button type="submit" class="btn btn-pills btn-primary">Subscribe</button>
            </div>
          </form>
          <!--end form-->
        </div>
        <!--end subscribe form-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Subscribe End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  
</a>
<!-- Back to top -->