<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/index" class="btn btn-icon btn-primary">
    
  </a>
</div>

<!-- Hero Start -->
<section class="bg-home bg-circle-gradiant d-flex align-items-center">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-8">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">Recover Account</h4>

            <form class="login-form mt-4">
              <div class="row">
                <div class="col-lg-12">
                  <p class="text-muted">Please enter your email address. You will receive a link to create a new
                    password via email.</p>
                  <div class="mb-3">
                    <label class="form-label">Email address <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      
                      <input type="email" class="form-control ps-5" placeholder="Enter Your Email Address" name="email"
                        required="">
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-lg-12">
                  <div class="d-grid">
                    <button class="btn btn-primary">Send</button>
                  </div>
                </div>
                <!--end col-->
                <div class="mx-auto">
                  <p class="mb-0 mt-3"><small class="text-dark me-2">Remember your password ?</small> <a
                      routerLink="/auth-login-three" class="text-dark fw-bold">Sign in</a></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->