import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import EnvironmentService from "./environment.service";
import { Product } from "./product";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  products: Observable<Product[]>;
  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService
  ) {}

  public getProducts(): Observable<Product[]> {
    if (!this.products) {
      this.products = this.httpClient.get<Product[]>(
        `${this.environmentService.apiBasePath}/products`
      );
    }

    return this.products;
  }
}
