import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import EnvironmentService from "./environment.service";
import { Process } from "./Process";
import { Product } from "./product";

@Injectable({
  providedIn: "root",
})
export default class ProcessService {
  products: Observable<Product[]>;
  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService
  ) {}

  public GetCurrentProcess(sessionId: string): Observable<Process[]> {
    return this.httpClient.get<Process[]>(
      `${this.environmentService.apiBasePath}/services/bySessionId?sessionId=${sessionId}`);
  }
}

