import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface member {
  profile: string,
  list: memberLink[],
  name: string,
  designation: string,
};

interface memberLink {
  link: string,  
  name: string
};

@Component({
  selector: 'app-page-aboutus',
  templateUrl: './page-aboutus.component.html',
  styleUrls: ['./page-aboutus.component.css']
})

/**
 * Aboutus Component
 */
export class PageAboutusComponent implements OnInit {
  footerClass: true;
  footerVariant = "bg-light";
  /**
   * Member Data
   */
  memberData: member[] = [
    {
      profile: "assets/images/nicolaj.jpg",
      list: [{name: "linkedin", link: "https://www.linkedin.com/in/nicolaj-helmer-hartmann"}],
      name: "Nicolaj Helmer Hartmann",
      designation: "CEO and founder"
    }
  ];

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
