import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import EnvironmentService from "./environment.service";
import { Order } from "./Order";
import { Product } from "./product";
import { SubscriptionUrl } from "./SubscriptionUrl";

@Injectable({
  providedIn: "root",
})
export default class OrderService {
  products: Observable<Product[]>;
  constructor(
    private httpClient: HttpClient,
    private environmentService: EnvironmentService
  ) {}

  public SubmitOrder(order: Order): Observable<SubscriptionUrl> {
    return this.httpClient.post<SubscriptionUrl>(
      `${this.environmentService.apiBasePath}/orders`,
      order,
      {
        headers: new HttpHeaders({ "x-cancel-url": window.location.href }),
        withCredentials: true,
      }
    );
  }
}
