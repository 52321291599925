<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <div>
      <a class="logo" routerLink="/" *ngIf="navClass !== 'nav-light'">
        <img src="assets/logo.svg" class="l-dark logo-light-mode" height="36" alt="">
      </a>
    </div>

    <span *ngIf="!buttonList && !isdeveloper && !shopPages">
      <div class="buy-button">
        <ng-template [ngIf]="navClass !== 'nav-light'">
          <a  routerLink="/order"  class="btn btn-primary">Get started</a>
        </ng-template>
      </div>
    </span>

    <!-- Mobile menu toggle-->
    <div class="menu-extras">
      <div class="menu-item">
    
        <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
    </div>

    <div id="navigation">
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">Home</a></li>
        <li><a routerLink="/order" class="nav-link-ref">Order</a></li>
        <li [hidden]="!givenName" ><a routerLink="/services" class="nav-link-ref">My services</a></li>

        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Docs</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" routerLink="/documentation/getting-started">Documentation</a></li>
            <li><a class="nav-link-ref" routerLink="/page-privacy">Privacy Policy</a></li>
            <li><a class="nav-link-ref" routerLink="/page-terms">Terms of services</a></li>
          </ul>
        </li>
        
        <li class="nav-link-ref text-center" [hidden]="!givenName">
          <a routerLink="/services"class="pb-0">Hello {{givenName}} <br/> <a (click)="logout()" class="p-0 text-muted">(sign out)</a> </a>
        </li>
        <li class="nav-link-ref text-center" [hidden]="givenName">
          <a (click)="login()" class="pb-0 pointer">Sign in</a>
        </li>
      </ul>
    </div>
  </div>
</header>
