<!-- Hero Start -->
<section class="section pt-5 pb-0 mt-4" id="home">
  <div class="container-fluid mt-md-1 px-0 px-md-3">
    <div class="rounded bg-light py-5 px-3 px-sm-0">
      <div class="row">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 col-12">
              <div class="title-heading me-lg-4">
                <div class="alert alert-primary alert-pills shadow" role="alert">
                  <span class="content"> Are you ready to learn online ?</span>
                </div>

                <h1 class="heading mb-3">Start Online Learning <br> With <span class="text-primary">: Landrick</span>
                </h1>
                <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and
                  managing conversion centered bootstrap v5 html page.</p>
                <div class="subcribe-form mt-4 pt-2">
                  <form class="m-0">
                    <div class="form-group">
                      <input type="text" id="course" name="name" class="rounded" placeholder="Search your course">
                      <button type="submit" class="btn btn-primary">Search
                        
                      </button>
                    </div>
                  </form>
                  <!--end form-->
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div class="position-relative">
                <img src="assets/images/course/online/hero.jpg" class="rounded img-fluid mx-auto d-block" alt="">
                <div class="play-icon">
                  <a (click)="openWindowCustomClass(content)" href="javascript:void(0);"
                    class="play-btn video-play-icon">
                    <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                  </a>
                  <ng-template #content let-modal>
                    <div class="modal-header">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780" frameborder="0"
                        allowfullscreen allow="autoplay; encrypted-media"></iframe>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </div>
      <!--end row-->
    </div>
    <!--end div-->
  </div>
  <!--end container fluid-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Course list Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Get a choice of your course</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-5">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Designing</a></h5>
              <a href="javascript:void(0)" class="text-muted small">Learn More</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-5">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Development</a></h5>
              <a href="javascript:void(0)" class="text-muted small">Learn More</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-5">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Music</a></h5>
              <a href="javascript:void(0)" class="text-muted small">Learn More</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-5">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Photography</a></h5>
              <a href="javascript:void(0)" class="text-muted small">Learn More</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-5">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Marketing</a></h5>
              <a href="javascript:void(0)" class="text-muted small">Learn More</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-5">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">IT & Software</a></h5>
              <a href="javascript:void(0)" class="text-muted small">Learn More</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-5">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Business</a></h5>
              <a href="javascript:void(0)" class="text-muted small">Learn More</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 pt-2">
        <div class="card explore-feature border-0 rounded text-center bg-white">
          <div class="card-body py-5">
            <div class="icon rounded-circle shadow-lg d-inline-block">
              
            </div>
            <div class="content mt-3">
              <h5><a href="javascript:void(0)" class="title text-dark">Health & Feetness</a></h5>
              <a href="javascript:void(0)" class="text-muted small">Learn More</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-12">
        <div class="text-center mt-4 pt-2">
          <a href="javascript:void(0)" class="btn btn-primary">See More Courses
            
          </a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Course list End -->

<!-- Partners start -->
<section class="py-4 border-bottom border-top">
  <div class="container py-4">
    <app-clients-logo></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Start -->
<section class="section">
  <!-- About Start -->
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-lg">
              <div class="card-body p-0">
                <img src="assets/images/course/online/ab01.jpg" class="img-fluid" alt="work-image">
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <a href="javascript:void(0)" class="title text-white d-block fw-bold">Web Development</a>
                  <small class="text-light">IT & Software</small>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-lg">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/ab02.jpg" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Michanical
                        Engineer</a>
                      <small class="text-light">Engineering</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-lg">
                  <div class="card-body p-0">
                    <img src="assets/images/course/online/ab03.jpg" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Chartered
                        Accountant</a>
                      <small class="text-light">C.A.</small>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ms-lg-4">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">About Our Story</h4>
            <p class="text-muted para-desc">Start working with <span class="text-primary fw-bold">Landrick</span> that
              can provide everything you need to generate awareness, drive traffic, connect.</p>
            <p class="text-muted para-desc mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to
              have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less
              corresponds to 'proper' Latin. It contains a series of real Latin words.</p>
          </div>

          <h5>Our Branches :</h5>

          <div class="row">
            <div class="col-md-6 col-12 mt-4">
              <div class="d-flex align-items-center">
                <i class="uil uil-map-marker text-muted h3"></i>
                <div class="content ms-2">
                  <h5 class="mb-0"><a href="#" class="video-play-icon text-primary">U.S.A.</a></h5>
                  <p class="text-muted mb-0">Long Street, WS, US</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 mt-4">
              <div class="d-flex align-items-center">
                <i class="uil uil-map-marker text-muted h3"></i>
                <div class="content ms-2">
                  <h5 class="mb-0"><a href="#" class="video-play-icon text-primary">China</a></h5>
                  <p class="text-muted mb-0">Wuhan, China</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12 mt-4">
              <div class="d-flex align-items-center">
                <i class="uil uil-map-marker text-muted h3"></i>
                <div class="content ms-2">
                  <h5 class="mb-0"><a href="#" class="video-play-icon text-primary">India</a></h5>
                  <p class="text-muted mb-0">Channai, India</p>
                </div>
              </div>
            </div>
          </div>

          <div class="watch-video mt-4 pt-2">
            <a href="#" target="_blank" class="btn btn-primary me-2">Read More
              
            </a>
            <a (click)="openWindow(contentmodal)" href="javascript:void(0);" data-type="youtube" data-id="yba7hPeTSjk"
              class="btn btn-icon btn-pills btn-primary m-1">
              
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #contentmodal let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <div class="d-flex features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-3">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Learners</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="d-flex features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-bag d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-3">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Teachers</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="d-flex features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-star d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-3">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Parents</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="d-flex features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-at d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-3">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Doners</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- About End -->

  <!-- Popular Course Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Popular Courses</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow overflow-hidden">
          <div class="position-relative">
            <img src="assets/images/course/1.jpg" class="card-img-top" alt="...">
            <div class="overlay bg-dark"></div>
            <div class="teacher d-flex align-items-center">
              <img src="assets/images/client/01.jpg" class="avatar avatar-md-sm rounded-circle shadow" alt="">
              <div class="ms-2">
                <h6 class="mb-0"><a href="javascript:void(0)" class="text-light user">Dung Lewis</a></h6>
                <p class="text-light small my-0">Professor</p>
              </div>
            </div>
            <div class="course-fee bg-white text-center shadow-lg rounded-circle">
              <h6 class="text-primary fw-bold fee">$11</h6>
            </div>
          </div>
          <div class="position-relative">
            <div class="shape overflow-hidden text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
              </svg>
            </div>
          </div>
          <div class="card-body content">
            <small><a href="javascript:void(0)" class="text-primary h6">Design</a></small>
            <h5 class="mt-2"><a href="javascript:void(0)" class="title text-dark">Program for Missionaries</a></h5>
            <p class="text-muted">The most well-known dummy text is the have originated in the 16th century.</p>
            <a href="javascript:void(0)" class="text-primary">Read More <i
                class="uil uil-angle-right-b align-middle"></i></a>
            <ul class="list-unstyled d-flex justify-content-between border-top mt-3 pt-3 mb-0">
              <li class="text-muted small"><i class="uil uil-book-open text-info"></i> 25 Lectures</li>
              <li class="text-muted small ms-3"><i class="uil uil-clock text-warning"></i> 1h 30m</li>
              <li class="text-muted small ms-3"><i class="uil uil-eye text-primary"></i> 3012</li>
            </ul>
          </div>
        </div>
        <!--end card / course-blog-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow overflow-hidden">
          <div class="position-relative">
            <img src="assets/images/course/2.jpg" class="card-img-top" alt="...">
            <div class="overlay bg-dark"></div>
            <div class="teacher d-flex align-items-center">
              <img src="assets/images/client/02.jpg" class="avatar avatar-md-sm rounded-circle shadow" alt="">
              <div class="ms-2">
                <h6 class="mb-0"><a href="javascript:void(0)" class="text-light user">Lisa Marvel</a></h6>
                <p class="text-light small my-0">Professor</p>
              </div>
            </div>
            <div class="course-fee bg-white text-center shadow-lg rounded-circle">
              <h6 class="text-primary fw-bold fee">$15</h6>
            </div>
          </div>
          <div class="position-relative">
            <div class="shape overflow-hidden text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
              </svg>
            </div>
          </div>
          <div class="card-body content">
            <small><a href="javascript:void(0)" class="text-primary h6">Development</a></small>
            <h5 class="mt-2"><a href="javascript:void(0)" class="title text-dark">Access to Higher Education</a></h5>
            <p class="text-muted">The most well-known dummy text is the have originated in the 16th century.</p>
            <a href="javascript:void(0)" class="text-primary">Read More <i
                class="uil uil-angle-right-b align-middle"></i></a>
            <ul class="list-unstyled d-flex justify-content-between border-top mt-3 pt-3 mb-0">
              <li class="text-muted small"><i class="uil uil-book-open text-info"></i> 25 Lectures</li>
              <li class="text-muted small ms-3"><i class="uil uil-clock text-warning"></i> 1h 30m</li>
              <li class="text-muted small ms-3"><i class="uil uil-eye text-primary"></i> 3012</li>
            </ul>
          </div>
        </div>
        <!--end card / course-blog-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow overflow-hidden">
          <div class="position-relative">
            <img src="assets/images/course/3.jpg" class="card-img-top" alt="...">
            <div class="overlay bg-dark"></div>
            <div class="teacher d-flex align-items-center">
              <img src="assets/images/client/03.jpg" class="avatar avatar-md-sm rounded-circle shadow" alt="">
              <div class="ms-2">
                <h6 class="mb-0"><a href="javascript:void(0)" class="text-light user">Amanda Carlo</a></h6>
                <p class="text-light small my-0">Professor</p>
              </div>
            </div>
            <div class="course-fee bg-white text-center shadow-lg rounded-circle">
              <h6 class="text-primary fw-bold fee">$19</h6>
            </div>
          </div>
          <div class="position-relative">
            <div class="shape overflow-hidden text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
              </svg>
            </div>
          </div>
          <div class="card-body content">
            <small><a href="javascript:void(0)" class="text-primary h6">Software</a></small>
            <h5 class="mt-2"><a href="javascript:void(0)" class="title text-dark">Educational Communication</a></h5>
            <p class="text-muted">The most well-known dummy text is the have originated in the 16th century.</p>
            <a href="javascript:void(0)" class="text-primary">Read More <i
                class="uil uil-angle-right-b align-middle"></i></a>
            <ul class="list-unstyled d-flex justify-content-between border-top mt-3 pt-3 mb-0">
              <li class="text-muted small"><i class="uil uil-book-open text-info"></i> 25 Lectures</li>
              <li class="text-muted small ms-3"><i class="uil uil-clock text-warning"></i> 1h 30m</li>
              <li class="text-muted small ms-3"><i class="uil uil-eye text-primary"></i> 3012</li>
            </ul>
          </div>
        </div>
        <!--end card / course-blog-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow overflow-hidden">
          <div class="position-relative">
            <img src="assets/images/course/4.jpg" class="card-img-top" alt="...">
            <div class="overlay bg-dark"></div>
            <div class="teacher d-flex align-items-center">
              <img src="assets/images/client/04.jpg" class="avatar avatar-md-sm rounded-circle shadow" alt="">
              <div class="ms-2">
                <h6 class="mb-0"><a href="javascript:void(0)" class="text-light user">Anne McKnight</a></h6>
                <p class="text-light small my-0">Professor</p>
              </div>
            </div>
            <div class="course-fee bg-white text-center shadow-lg rounded-circle">
              <h6 class="text-primary fw-bold fee">$9</h6>
            </div>
          </div>
          <div class="position-relative">
            <div class="shape overflow-hidden text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
              </svg>
            </div>
          </div>
          <div class="card-body content">
            <small><a href="javascript:void(0)" class="text-primary h6">Music</a></small>
            <h5 class="mt-2"><a href="javascript:void(0)" class="title text-dark">Introduction to Epidemiology</a></h5>
            <p class="text-muted">The most well-known dummy text is the have originated in the 16th century.</p>
            <a href="javascript:void(0)" class="text-primary">Read More <i
                class="uil uil-angle-right-b align-middle"></i></a>
            <ul class="list-unstyled d-flex justify-content-between border-top mt-3 pt-3 mb-0">
              <li class="text-muted small"><i class="uil uil-book-open text-info"></i> 25 Lectures</li>
              <li class="text-muted small ms-3"><i class="uil uil-clock text-warning"></i> 1h 30m</li>
              <li class="text-muted small ms-3"><i class="uil uil-eye text-primary"></i> 3012</li>
            </ul>
          </div>
        </div>
        <!--end card / course-blog-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow overflow-hidden">
          <div class="position-relative">
            <img src="assets/images/course/5.jpg" class="card-img-top" alt="...">
            <div class="overlay bg-dark"></div>
            <div class="teacher d-flex align-items-center">
              <img src="assets/images/client/05.jpg" class="avatar avatar-md-sm rounded-circle shadow" alt="">
              <div class="ms-2">
                <h6 class="mb-0"><a href="javascript:void(0)" class="text-light user">Leosy Murfhy</a></h6>
                <p class="text-light small my-0">Professor</p>
              </div>
            </div>
            <div class="course-fee bg-white text-center shadow-lg rounded-circle">
              <h6 class="text-primary fw-bold fee">$24</h6>
            </div>
          </div>
          <div class="position-relative">
            <div class="shape overflow-hidden text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
              </svg>
            </div>
          </div>
          <div class="card-body content">
            <small><a href="javascript:void(0)" class="text-primary h6">Art & Fashion</a></small>
            <h5 class="mt-2"><a href="javascript:void(0)" class="title text-dark">Good Clinical Practice</a></h5>
            <p class="text-muted">The most well-known dummy text is the have originated in the 16th century.</p>
            <a href="javascript:void(0)" class="text-primary">Read More <i
                class="uil uil-angle-right-b align-middle"></i></a>
            <ul class="list-unstyled d-flex justify-content-between border-top mt-3 pt-3 mb-0">
              <li class="text-muted small"><i class="uil uil-book-open text-info"></i> 25 Lectures</li>
              <li class="text-muted small ms-3"><i class="uil uil-clock text-warning"></i> 1h 30m</li>
              <li class="text-muted small ms-3"><i class="uil uil-eye text-primary"></i> 3012</li>
            </ul>
          </div>
        </div>
        <!--end card / course-blog-->
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog rounded border-0 shadow overflow-hidden">
          <div class="position-relative">
            <img src="assets/images/course/6.jpg" class="card-img-top" alt="...">
            <div class="overlay bg-dark"></div>
            <div class="teacher d-flex align-items-center">
              <img src="assets/images/client/06.jpg" class="avatar avatar-md-sm rounded-circle shadow" alt="">
              <div class="ms-2">
                <h6 class="mb-0"><a href="javascript:void(0)" class="text-light user">Cristino Murfhy</a></h6>
                <p class="text-light small my-0">Professor</p>
              </div>
            </div>
            <div class="course-fee bg-white text-center shadow-lg rounded-circle">
              <h6 class="text-primary fw-bold fee">$21</h6>
            </div>
          </div>
          <div class="position-relative">
            <div class="shape overflow-hidden text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
              </svg>
            </div>
          </div>
          <div class="card-body content">
            <small><a href="javascript:void(0)" class="text-primary h6">Programmer</a></small>
            <h5 class="mt-2"><a href="javascript:void(0)" class="title text-dark">Social Computing</a></h5>
            <p class="text-muted">The most well-known dummy text is the have originated in the 16th century.</p>
            <a href="javascript:void(0)" class="text-primary">Read More <i
                class="uil uil-angle-right-b align-middle"></i></a>
            <ul class="list-unstyled d-flex justify-content-between border-top mt-3 pt-3 mb-0">
              <li class="text-muted small"><i class="uil uil-book-open text-info"></i> 25 Lectures</li>
              <li class="text-muted small ms-3"><i class="uil uil-clock text-warning"></i> 1h 30m</li>
              <li class="text-muted small ms-3"><i class="uil uil-eye text-primary"></i> 3012</li>
            </ul>
          </div>
        </div>
        <!--end card / course-blog-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Popular Course End -->

  <!-- CTA Start -->
  <div class="container-fluid mt-100 mt-60">
    <div class="rounded py-md-5" style="background: url('assets/images/course/online/cta.jpg') center center;">
      <div class="row py-5">
        <div class="container">
          <div class="row align-items-center px-3 px-sm-0">
            <div class="col-lg-8 col-md-6 col-12">
              <div class="section-title">
                <h4 class="display-4 h1 text-white title-dark mb-4">Register Now !</h4>
                <p class="text-light title-dark para-desc">Start working with Landrick that can provide everything you
                  need to generate awareness, drive traffic, connect.</p>
                <div class="mt-4">
                  <a href="javascript:void(0)" class="btn btn-primary">Admission Now</a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div class="card login_page shadow rounded border-0">
                <div class="card-body">
                  <h4 class="card-title">Register Now</h4>
                  <form class="login-form">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Your Name :<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            
                            <input name="name" id="name" type="text" class="form-control ps-5"
                              placeholder="First Name :">
                          </div>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Your Email :<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            
                            <input name="email" id="email" type="email" class="form-control ps-5"
                              placeholder="Your email :">
                          </div>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Your Phone no. :<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            
                            <input name="number" id="number" type="number" class="form-control ps-5"
                              placeholder="Your phone no. :">
                          </div>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-md-12">
                        <div class="mb-3">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">I Accept <a
                                routerLink="/index-online-learning" class="text-primary">Terms And Condition</a></label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <button class="btn btn-primary w-100">Register Now</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </div>
      <!---end row-->
    </div>
    <!--end div-->
  </div>
  <!--end container fluid-->
  <!-- CTA End -->

  <!-- Teachers Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Our Instructor</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/05.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="flex-1 content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Krista John</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/06.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="flex-1 content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Jack John</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/01.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="flex-1 content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Roger Jackson</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/02.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="flex-1 content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Luchhi Cina</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/03.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="flex-1 content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Sophiya Cally</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="d-flex align-items-center">
          <img src="assets/images/client/04.jpg" class="avatar avatar-medium rounded-circle img-thumbnail" alt="">
          <div class="flex-1 content ms-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="text-dark">Johnny English</a></h5>
            <small class="position text-muted">Professor</small>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Teachers End -->

  <!-- Testi Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">What Students Say ?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
        </div>
      </div>
  </div>
  <!--end container-->
  <!-- Testi End -->

  <!-- Blog Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Latest News</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-blog [blogData]="blogData"></app-blog>
  </div>
  <!--end container-->
  <!-- Blog End -->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  
</a>
<!-- Back to top -->