import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export default class EnvironmentService {
  basePath: string;
  apiBasePath: string;

  constructor() {
    this.basePath = window.location.origin.replace('https://https', 'https');
    this.apiBasePath = this.basePath.indexOf("localhost") === -1 ? `${this.basePath}/api` : "https://localhost:5001/api";
   }
}
