<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Privacy Policy </h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised :</span> 5th May, 2022</li>
          </ul>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">TATIC.IO</a></li>
                <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <h4>Privacy Policy</h4>
            <h5>General Information</h5>
            <p>This privacy policy applies to all online services of Tatic ApS.<br/><br/>Tatic.ApS<br/>Mejsevej 2<br/>DK-8370 Hadsten<br/>Denmark<br/>phone: +45 70 20 60 85<br/>email: info@tatic.io<br/>VAT ID: DK42532940</p>
            <p>Nicolaj Helmer Hartmann is the data protection officer, data-protection@tatic.io</p>
            <h5>HOW DO YOU PROCESS PERSONAL DATA?</h5>
            <p>We store and use your personal data only to process your order and communicate with you. If you subscribe to our customer newsletter, we also use your email address to send you newsletters. We will automatically send you status messages from tatic.io if you have previously changed settings in the client admin interface that requested these messages. We also store and process data that applicants provide to us during the application process.</p>
            <h5>WHAT CATEGORIES OF DATA DO WE COLLECT?</h5>
            <p>Whenever you visit our website, your IP address and other information are stored anonymously. If you register for an account with us, your contact information will be stored. If you order from us, your address and billing information will also be stored.</p>
            <p>In particular, we save the following data from you:</p>
            <h5>REGISTRATION DATA</h5>
            <p>When you register an account with us, we collect and process certain personal data from you as registration data. For example, we need your name, address information, phone number, billing information, and email address in order to process your order. If you pay by credit card, we do not collect or store payment information such as credit card numbers or verification codes. You disclose this information only to the relevant payment service provider. We delete all your registration data: within 24 months once you delete your account with us, or after the legal retention period expires. You can delete your account with us by sending an email to data-protection@tatic.io requesting deletion. The legal basis for this data processing is Art. 6 (1) lit. b GDPR.</p>
            <h5>EMAIL ADDRESSES</h5>
            <p>If you subscribed to our newsletter, if someone invited you to a tatic.io service, or if you requested that you receive automated messages from tatic.io</p>
            <p>We will delete your email address once you delete your user account</p>
            <p>We use Sendinblue as our email software. Sendinblue is a certified German provider that we chose because it must comply with the EU General Data Protection Regulation (GDPR).</p>
           <p>You can find more information on Sendinblue here:<br/>
            https://www.sendinblue.com/legal/termsofuse/?rtype=n2go</p>
            <h5>PAYMENT PROCESSING</h5>
            <p>All payment processing is handled by Stripe. For more information about privacy handled by stripe please visit <a href="https://stripe.com/en-gb-dk/guides/general-data-protection-regulation">https://stripe.com/en-gb-dk/guides/general-data-protection-regulation</a></p>>
            <h5>WEBSITE ANALYSIS AND CHAT PLATFORM</h5>
            <p>We are using Tawk.io for all chat interactivity on tatic.io. For more information about privacy handled by tawk please visit: <a href="https://www.tawk.to/privacy-policy/">https://www.tawk.to/privacy-policy/</a></p>
            <h5>COOKIES AND THE RIGHT TO OBJECT TO DIRECT ADVERTISING</h5>
            <p>"Cookies" are small files that are stored on a user's computer. Cookies can store different kinds of data. Cookies are primarily used to store information about users. They are stored on the users' devices during and/or after users visit a website or online store. "Temporary cookies", "session cookies", or "transient cookies" are cookies that are deleted after users leave an online store and close their browsers. With such cookies, for example, the content of a shopping basket can be stored in an online shop or a login bottleneck. However, other cookies are referred to as "permanent" or "persistent" and remain stored even after the browser is closed. For example, users' login status can be saved when they visit a shop after several days. Likewise, permanent cookies can measure the "range" of a website (where the users are geographically located) or information on the users' interests. This information is used for marketing purposes. "Third-party cookies" are cookies that are offered by the original providers (who operate the online store or website) to other parties. (If the original provider is the one using the cookies, they are often called "first-party cookies"). It is useful to know the above terms so that you understand our cookie policies and data protection agreement. If, as a tatic.io customer or as a visitor to our websites or interfaces, you do not wish cookies to be stored on your computer, we ask you to deactivate the corresponding option in the system settings of your browser. You can also delete stored cookies in the system settings of your browser. However, if you do these things, it will likely lead to problems when you try to use Tatic.io websites and interfaces. If you would like to opt out of the use of cookies for online marketing and tracking purposes, you may be interested these two sites to help you:<br/>
              1) the US site http://www.aboutads.info/choices/<br/>
              2) the EU site http://www.youronlinechoices.com/.<br/>>
              Furthermore, you can deactivate cookies from being stored on your computer or device by changing your browser settings. However, if you do this, it will likely lead to problems when you try to use Tatic ApS websites, interfaces, and features on the interfaces.</p>
            <h5>DDOS PROTECTION AND EDGE NETWORKING</h5>
            <p>In order to secure high availability of all our services we are providing DDOS protection through Cloudflare. Cloudflare is ensuring secure and encrypted connectivity between you and the servers provided by tatic.io. Cloudflare is committed to ensure GDPR compliance. More information can be found here: <a href="https://www.cloudflare.com/gdpr/introduction/">https://www.cloudflare.com/gdpr/introduction/</a></p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  
</a>
<!-- Back to top -->