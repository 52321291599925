<div *ngIf="!hideFooter">
  <div *ngIf="footerVariant == 'bg-light'; else execute_block">
    <!-- Footer Start -->
    <footer class="footer bg-light">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img src="assets/logo.svg" height="24" alt="" />
            </a>
            <p class="mt-4 text-muted">
              Start using Opensearch with a minimum effort and get all the
              benefits from a european hosted logging solution.
            </p>
            <ul class="list-unstyled social-icon social mb-0 mt-4">
              <li class="list-inline-item">
                <a
                  href="https://www.facebook.com/tatic.logging/"
                  target="_blank"
                  class="rounded"
                >

                </a>
              </li>
              <li class="list-inline-item ms-1">
                <a
                  href="https://www.linkedin.com/company/tatic-io/"
                  target="_blank"
                  class="rounded"
                >

                </a>
              </li>
              <li class="list-inline-item ms-1">
                <a
                  href="https://github.com/Tatic-io"
                  target="_blank"
                  class="rounded"
                >

                </a>
              </li>
            </ul>
            <!--end icon-->
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-dark footer-head">Company</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <a routerLink="/about-us" class="text-muted"
                  ><i class="uil uil-angle-right-b me-1"></i> About us</a
                >
              </li>
              <!-- <li><a routerLink="/page-services" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i>
                                    Services</a></li>
                            <li><a routerLink="/page-team" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Team</a></li> -->
              <li>
                <a
                  [routerLink]="['']"
                  fragment="pricing-table"
                  class="text-muted"
                  ><i class="uil uil-angle-right-b me-1"></i> Pricing</a
                >
              </li>
              <!-- <li><a routerLink="/portfolio-modern-three" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i> Project</a></li>
                            <li><a routerLink="/page-jobs" class="text-muted"><i class="uil uil-angle-right-b me-1"></i>
                                    Careers</a></li>
                            <li><a routerLink="/page-blog-grid" class="text-muted"><i
                                        class="uil uil-angle-right-b me-1"></i> Blog</a></li> -->
              <li>
                <a (click)="login()" class="text-muted pointer"
                  ><i class="uil uil-angle-right-b me-1"></i> Login</a
                >
              </li>
            </ul>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-dark footer-head">Useful Links</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <a routerLink="/page-terms" class="text-muted"
                  ><i class="uil uil-angle-right-b me-1"></i>Terms of
                  Services</a
                >
              </li>
              <li>
                <a routerLink="/page-privacy" class="text-muted"
                  ><i class="uil uil-angle-right-b me-1"></i>Privacy Policy</a
                >
              </li>
              <li>
                <a routerLink="/documentation" class="text-muted"
                  ><i class="uil uil-angle-right-b me-1"></i>Documentation</a
                >
              </li>

              <li>
                <a routerLink="/documentation/getting-started" class="text-muted"
                  ><i class="uil uil-angle-right-b me-1"></i>Quick start guide</a
                >
              </li>


              <li>
                <a href="https://tatic.statuspage.io/" target="_blank" class="text-muted"
                  ><i class="uil uil-angle-right-b me-1"></i>Status page</a
                >
              </li>
            </ul>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-dark footer-head">Tatic ApS</h5>
            <p class="mt-4 text-muted">
              Mejsevej 2 <br />DK-8370 Hadsten<br />Denmark<br />Phone:
              <a href="tel:+4570206085">+45 70206085</a><br />Mail:
              <a href="mailto:info@tatic.io">info@tatic.io</a>
            </p>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!-- Footer End -->
  </div>
  <ng-template #execute_block>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img src="assets/images/logo-light.png" height="24" alt="" />
            </a>
            <p class="mt-4">
              Start working with Landrick that can provide everything you need
              to generate awareness, drive traffic, connect.
            </p>
            <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
              <li class="list-inline-item">
                <a href="javascript:void(0)" class="rounded">

                </a>
              </li>
              <li class="list-inline-item ms-1">
                <a href="javascript:void(0)" class="rounded">

                </a>
              </li>
              <li class="list-inline-item ms-1">
                <a href="javascript:void(0)" class="rounded">

                </a>
              </li>
              <li class="list-inline-item ms-1">
                <a href="javascript:void(0)" class="rounded">

                </a>
              </li>
            </ul>
            <!--end icon-->
          </div>
          <!--end col-->

          <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Company</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <a routerLink="/about-us" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> About us</a
                >
              </li>
              <li>
                <a routerLink="/page-services" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> Services</a
                >
              </li>
              <li>
                <a routerLink="/page-team" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> Team</a
                >
              </li>
              <li>
                <a routerLink="/page-pricing" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> Pricing</a
                >
              </li>
              <li>
                <a routerLink="/portfolio-modern-three" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> Project</a
                >
              </li>
              <li>
                <a routerLink="/page-jobs" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> Careers</a
                >
              </li>
              <li>
                <a routerLink="/page-blog-grid" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> Blog</a
                >
              </li>
              <li>
                <a routerLink="/auth-cover-login" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> Login</a
                >
              </li>
            </ul>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Useful Links</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <a routerLink="/page-terms" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> Terms of
                  Services</a
                >
              </li>
              <li>
                <a routerLink="/page-privacy" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> Privacy Policy</a
                >
              </li>
              <li>
                <a routerLink="/documentation/getting-started" class="text-foot"
                  ><i class="uil uil-angle-right-b me-1"></i> Documentation</a
                >
              </li>
            </ul>
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Newsletter</h5>
            <p class="mt-4">Sign up and receive the latest tips via email.</p>
            <form>
              <div class="row">
                <div class="col-lg-12">
                  <div class="foot-subscribe foot-white mb-3">
                    <label class="form-label"
                      >Write your email
                      <span class="text-danger">*</span></label
                    >
                    <div class="form-icon position-relative">

                      <input
                        type="email"
                        name="email"
                        id="emailsubscribe"
                        class="form-control ps-5 rounded"
                        placeholder="Your email : "
                        required
                      />
                    </div>
                  </div>
                </div>
                <!--end col-->
                <div class="col-lg-12">
                  <div class="d-grid">
                    <input
                      type="submit"
                      id="submitsubscribe"
                      name="send"
                      class="btn btn-soft-primary"
                      value="Subscribe"
                    />
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
  </ng-template>
</div>
<!--end footer-->
<footer class="footer footer-bar">
  <div class="container text-center">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="text-sm-start">
          <p class="mb-0">
            ©
            <script
              type="text/javascript"
              id="www-widgetapi-script"
              src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js"
              async=""
            ></script>
            <script
              id="iframe_api"
              src="https://www.youtube.com/iframe_api"
            ></script>
            {{ year }}
            Tatic.io - Hosted in europe
          </p>
        </div>
      </div>
      <!--end col-->

      <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <ul class="list-unstyled text-sm-end mb-0">
          <li class="list-inline-item">
            <a href="javascript:void(0)"
              ><img
                src="assets/stripe-white.svg"
                class="avatar avatar-ex-sm"
                title="American Express"
                alt=""
            /></a>
          </li>
        </ul>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</footer>
<!--end footer-->
