import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { interval, Observable } from "rxjs";
import { Process } from "src/app/services/Process";
import ProcessService from "src/app/services/process.service";

@Component({
  selector: "app-index-services",
  templateUrl: "./index-services.component.html",
  styleUrls: ["./index-services.component.css"],
})

/***
 * Services Component
 */
export class IndexServicesComponent implements OnInit {
  /***
   * Footer bg color set
   */
  footerClass: true;
  footerVariant = "bg-light";
  sessionId: any;
  processes: Process[] = [];

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private processService: ProcessService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.sessionId = params.sessionId;
      if (this.sessionId) {
        this.processService.GetCurrentProcess(this.sessionId).subscribe((y) => {
          this.processes = y;
        });

        interval(3000).subscribe(() => {
          this.processService
            .GetCurrentProcess(this.sessionId)
            .subscribe((y) => {
              this.processes = y;
            });
        });
      }
    });
  }
}
