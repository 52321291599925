<!-- Hero Start -->
<section class="bg-half-260 d-table w-100" data-jarallax='{"speed": 0.5}'
    style="background:url('assets/images/finance/bg.jpg') top" id="home">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title-heading">
                    <div class="alert alert-white alert-pills shadow" role="alert">
                        <span class="badge rounded-pill bg-primary me-1">Trusted</span>
                        <span class="content"> Funds held securely and relibly</span>
                    </div>
                    <h1 class="heading fw-bold mb-3">
                        <ngx-typed-js [strings]="[ 'Agency', 'Business', 'Startups', 'Studio' ]" [shuffle]="true"
                            [typeSpeed]="200" [loop]="true">
                            Help your <span class="element text-primary typing"></span>
                        </ngx-typed-js>
                        Growth up to high level
                    </h1>
                    <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and
                        managing conversion centered bootstrap v5 html page.</p>
                    <div class="mt-4 pt-2">
                        <a href="javascript:void(0)" class="btn btn-primary">Contact us</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Features -->
<section class="bg-light">
    <div class="container-fluid px-0">
        <div class="row g-0 align-items-center">
            <div class="col-xl-2 col-lg-4 col-md-4">
                <div
                    class="card features fea-primary text-center rounded-0 px-4 py-5 bg-light bg-gradient position-relative overflow-hidden border-0">
                    <span class="h2 icon2 text-primary">
                        <i class="uil uil-crosshair"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Strategy</h5>
                        <p class="para text-muted mb-0">It is a long established fact that a reader will be will be of
                            at its layout.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-xl-2 col-lg-4 col-md-4">
                <div
                    class="card features fea-primary text-center rounded-0 px-4 py-5 bg-light bg-gradient position-relative overflow-hidden border-0">
                    <span class="h2 icon2 text-primary">
                        <i class="uil uil-search"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Research</h5>
                        <p class="para text-muted mb-0">It is a long established fact that a reader will be will be of
                            at its layout.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-xl-2 col-lg-4 col-md-4">
                <div
                    class="card features fea-primary text-center rounded-0 px-4 py-5 bg-light bg-gradient position-relative overflow-hidden border-0">
                    <span class="h2 icon2 text-primary">
                        <i class="uil uil-lightbulb-alt"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Planning</h5>
                        <p class="para text-muted mb-0">It is a long established fact that a reader will be will be of
                            at its layout.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-xl-2 col-lg-4 col-md-4">
                <div
                    class="card features fea-primary text-center rounded-0 px-4 py-5 bg-light bg-gradient position-relative overflow-hidden border-0">
                    <span class="h2 icon2 text-primary">
                        <i class="uil uil-usd-circle"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Assurance</h5>
                        <p class="para text-muted mb-0">It is a long established fact that a reader will be will be of
                            at its layout.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-xl-2 col-lg-4 col-md-4">
                <div
                    class="card features fea-primary text-center rounded-0 px-4 py-5 bg-light bg-gradient position-relative overflow-hidden border-0">
                    <span class="h2 icon2 text-primary">
                        <i class="uil uil-analytics"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Analysis</h5>
                        <p class="para text-muted mb-0">It is a long established fact that a reader will be will be of
                            at its layout.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-xl-2 col-lg-4 col-md-4">
                <div
                    class="card features fea-primary text-center rounded-0 px-4 py-5 bg-light bg-gradient position-relative overflow-hidden border-0">
                    <span class="h2 icon2 text-primary">
                        <i class="uil uil-invoice"></i>
                    </span>
                    <div class="card-body p-0 content">
                        <h5>Investment</h5>
                        <p class="para text-muted mb-0">It is a long established fact that a reader will be will be of
                            at its layout.</p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Features -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                        <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                            <div class="card-body p-0">
                                <img src="assets/images/course/online/ab01.jpg" class="img-fluid" alt="work-image">
                                <div class="overlay-work bg-dark"></div>
                                <div class="content">
                                    <a href="javascript:void(0)" class="title text-white d-block fw-bold">Web
                                        Development</a>
                                    <small class="text-light">IT & Software</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-6 col-6">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                    <div class="card-body p-0">
                                        <img src="assets/images/course/online/ab02.jpg" class="img-fluid"
                                            alt="work-image">
                                        <div class="overlay-work bg-dark"></div>
                                        <div class="content">
                                            <a href="javascript:void(0)"
                                                class="title text-white d-block fw-bold">Michanical Engineer</a>
                                            <small class="text-light">Engineering</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-lg-12 col-md-12 mt-4 pt-2">
                                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                    <div class="card-body p-0">
                                        <img src="assets/images/course/online/ab03.jpg" class="img-fluid"
                                            alt="work-image">
                                        <div class="overlay-work bg-dark"></div>
                                        <div class="content">
                                            <a href="javascript:void(0)"
                                                class="title text-white d-block fw-bold">Chartered Accountant</a>
                                            <small class="text-light">C.A.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
                <div class="ms-lg-4">
                    <div class="section-title">
                        <span class="badge bg-soft-primary rounded-pill fw-bold">About us</span>
                        <h4 class="title mb-4 mt-3">We design and develop <br> world-class web applications.</h4>
                        <p class="text-muted para-desc">Start working with <span
                                class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                            generate awareness, drive traffic, connect. The most well-known dummy text is the 'Lorem
                            Ipsum', which is said to have originated in the 16th century.</p>
                    </div>

                    <div class="mt-4 pt-2">
                        <a href="https://1.envato.market/4n73n" target="_blank" class="btn btn-primary m-1">Read More <i
                                class="uil uil-angle-right-b align-middle"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <i class="uil uil-chart-pie-alt text-primary h2"></i>
                    <h4 class="title mt-3 mb-4">Join The Next Revolution. <br> Create your Website Now!</h4>
                    <p class="text-muted mx-auto para-desc">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-6 mt-2">
                <div class="px-md-4 pt-4 text-center">
                    <h4 class="mb-3">Individual Services</h4>
                    <p class="text-muted mb-0">It is said that song composers of the past used dummy texts as lyrics
                        when writing melodies in order.</p>

                    <div class="position-relative mt-5">
                        <div class="bg-overlay bg-primary bg-gredient rounded-md" style="opacity: 0.6;"></div>
                        <img src="assets/images/finance/1.jpg" class="rounded-md shadow img-fluid" alt="">
                    </div>

                    <div class="px-md-4 pt-4 text-start">
                        <div class="d-flex">
                            <i class="uil uil-bullseye h5 text-primary"></i>
                            <div class="flex-1 ms-2">
                                <h5>Engaging Group Discussion​</h5>
                                <p class="mb-0 text-muted">It is advantageous when the dummy text is relatively
                                    realistic so that the layout impression</p>
                            </div>
                        </div>

                        <div class="d-flex mt-4">
                            <i class="uil uil-create-dashboard h5 text-primary"></i>
                            <div class="flex-1 ms-2">
                                <h5>Software Engineering​</h5>
                                <p class="mb-0 text-muted">It is advantageous when the dummy text is relatively
                                    realistic so that the layout impression</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 mt-2">
                <div class="px-md-4 pt-4 text-center">
                    <h4 class="mb-3">Organization Services</h4>
                    <p class="text-muted mb-0">It is said that song composers of the past used dummy texts as lyrics
                        when writing melodies in order.</p>

                    <div class="position-relative mt-5">
                        <div class="bg-overlay bg-primary bg-gredient rounded-md" style="opacity: 0.6;"></div>
                        <img src="assets/images/finance/2.jpg" class="rounded-md shadow img-fluid" alt="">
                    </div>

                    <div class="px-md-4 pt-4 text-start">
                        <div class="d-flex">
                            <i class="uil uil-bullseye h5 text-primary"></i>
                            <div class="flex-1 ms-2">
                                <h5>Engaging Group Discussion​</h5>
                                <p class="mb-0 text-muted">It is advantageous when the dummy text is relatively
                                    realistic so that the layout impression</p>
                            </div>
                        </div>

                        <div class="d-flex mt-4">
                            <i class="uil uil-create-dashboard h5 text-primary"></i>
                            <div class="flex-1 ms-2">
                                <h5>Software Engineering​</h5>
                                <p class="mb-0 text-muted">It is advantageous when the dummy text is relatively
                                    realistic so that the layout impression</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- CTA Start -->
<section class="section bg-cta" data-jarallax='{"speed": 0.5}'
    style="background: url('assets/images/finance/cta.jpg') center center;" id="cta">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title title-dark text-white mb-4">Stop leaving money on the table.</h4>
                    <p class="text-light para-dark para-desc mx-auto">Start working with Landrick that can provide
                        everything you need to generate awareness, drive traffic, connect.</p>
                    <a (click)="openModal(contentModal)" href="javascript:void(0);" data-type="youtube"
                        data-id="yba7hPeTSjk" class="play-btn border border-light mt-4">
                        
                    </a>
                    <ng-template #contentModal let-modal>
                        <div class="modal-header">
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                            </youtube-player>
                        </div>
                    </ng-template>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- CTA End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Partners Testimonial</h4>
                    <p class="text-muted mx-auto para-desc">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Latest News</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <app-blog [blogData]="blogData"></app-blog>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Partners start -->
<section class="py-4 bg-light">
    <div class="container py-4">
        <app-clients-logo></app-clients-logo>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
 
</a>
<!-- Back to top -->