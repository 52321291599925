<!-- Start Contact -->
<section class="section pt-5 mt-4" id="home">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
        <div class="card map border-0">
          <div class="card-body p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
              style="border:0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card custom-form rounded border-0 shadow p-4">
          <form method="post" name="myForm" onsubmit="return validateForm()">
            <p id="error-msg" class="mb-0"></p>
            <div id="simple-msg"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Name <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    
                    <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Email <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    
                    <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Subject</label>
                  <div class="form-icon position-relative">
                    
                    <input name="subject" id="subject" class="form-control ps-5" placeholder="subject :">
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Comments <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    
                    <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                      placeholder="Message :"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <button type="submit" id="submit" name="send" class="btn btn-primary">Send Message</button>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="title-heading ms-lg-4">
          <h4 class="mb-4">Contact Details</h4>
          <p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide
            everything you need to generate awareness, drive traffic, connect.</p>
          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Email</h6>
              <a href="mailto:contact@example.com" class="text-primary">contact@example.com</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Phone</h6>
              <a href="tel:+152534-468-854" class="text-primary">+152 534-468-854</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Location</h6>

              <a (click)="mapView(content)" data-type="iframe" class="video-play-icon text-primary">View on Google
                map</a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                    height="450" width="750">
                  </iframe>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" >
  
</a>
<!-- Back to top -->