<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/index" class="btn btn-icon btn-soft-primary">
    
  </a>
</div>

<!-- Hero Start -->
<section class="bg-auth-home d-table w-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="me-lg-5">
          <img src="assets/images/user/signup.svg" class="img-fluid d-block mx-auto" alt="">
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h4 class="card-title text-center">Signup</h4>
            <form class="login-form mt-4">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">First name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      
                      <input type="text" class="form-control ps-5" placeholder="First Name" name="s" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Last name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      
                      <input type="text" class="form-control ps-5" placeholder="Last Name" name="s" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      
                      <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Password <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      
                      <input type="password" class="form-control ps-5" placeholder="Password" required="">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-12">
                  <div class="mb-3">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                      <label class="form-check-label" for="flexCheckDefault">I Accept <a routerLink="/auth-signup"
                          class="text-primary">Terms And Condition</a></label>
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-12">
                  <div class="d-grid">
                    <button class="btn btn-primary">Register</button>
                  </div>
                </div>
                <!--end col-->

                <div class="col-lg-12 mt-4 text-center">
                  <h6>Or Signup With</h6>
                  <div class="row">
                    <div class="col-6 mt-3">
                      <div class="d-grid">
                        <a href="javascript:void(0)" class="btn btn-light"><i class="mdi mdi-facebook text-primary"></i>
                          Facebook</a>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-6 mt-3">
                      <div class="d-grid">
                        <a href="javascript:void(0)" class="btn btn-light"><i class="mdi mdi-google text-danger"></i>
                          Google</a>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                </div>
                <!--end col-->

                <div class="mx-auto">
                  <p class="mb-0 mt-3"><small class="text-dark me-2">Already have an account ?</small> <a
                      routerLink="/auth-login" class="text-dark fw-bold">Sign in</a></p>
                </div>
              </div>
              <!--end row-->
            </form>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->