import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-classic-saas',
  templateUrl: './index-classic-saas.component.html',
  styleUrls: ['./index-classic-saas.component.css']
})

/***
 * Classic Saas Component
 */
export class IndexClassicSaasComponent implements OnInit {

  footerClass: true;
  footerVariant = "bg-light";
  
  constructor(private modalService: NgbModal) { }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      900: {
        items: 1
      }
    },
    nav: false
  };

  /**
   * Services Data
   */
  servicesData = [ 
    {
      icon: "uil uil-cloud-database-tree h1 text-primary",
      title: "Backup every day",
      description: "To ensure that you never lose your data."
    },
    {
      icon: "uil uil-servers h1 text-primary",
      title: "High available infrastructure",
      description: "Redundant server and storage"
    },
    {
      icon: "uil uil-shield-check h1 text-primary",
      title: "Real time DDOS protection",
      description: "Cloudflare in front of all services"
    }
  ];

  /**
   * Pricing Data
   */
  pricingData = [
    {
      title: "Starter",
      price: 39,
      list: ["Full Access", "OpenSearch", "OpeanSearch Dashboard", "6GB memory + 2 vcores", "60GB SSD storage"],
      btn: "Buy Now",
      productId: "elastic_starter"
    },
    {
      warning: "Best Buy",
      title: "Value",
      price: 59,
      list: ["Full Access", "OpenSearch", "OpeanSearch Dashboard","14GB memory + 4 vcores", "120GB SSD storage"],
      btn: "Get Started"
    },
    {
      title: "Professional",
      price: 99,
      list: ["Full Access", "OpenSearch", "OpeanSearch Dashboard","30GB memory + 8 vcores", "200GB SSD storage"],
      btn: "Try It Now"
    },
    {
      title: "Ultimate Cluster",
      price: 149,
      list: ["3 nodes", "Full Access", "OpenSearch", "OpeanSearch Dashboard", "14GB memory + 4 vcores", "300GB NVME storage"],
      btn: "Started Now"
    }
  ];

  ngOnInit(): void {
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
