import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Params } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Entry } from "contentful";
import { Article, ContentfulService } from "src/app/contentful.service";

@Component({
  selector: "app-documentation",
  templateUrl: "./documentation.component.html",
  styleUrls: ["./documentation.component.css"],
})

/**
 * Documentation Component
 */
export class DocumentationComponent implements OnInit {
  footerClass: true;
  footerVariant = "bg-light";
  activatedRoute: string;
  activeArticle: Entry<any>;
  currentPath: any;

  constructor(
    private contentfulService: ContentfulService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private titleService: Title
      ) {}
  entries: Entry<any>[] = [];
 
  ngOnInit() {
    this.contentfulService
      .getArticles()
      .then((result) => this.setEntries(result));

  }

  setEntries(entries: Entry<Article>[]) {
    this.activatedRoute = this.route.snapshot.paramMap.get("path");
    this.entries = entries;
    this.activeArticle = entries.find(x=> x.fields.path === this.activatedRoute);
    this.route.params.subscribe((params:Params)=>{
      this.currentPath =  params['path'] // whenever route is changed, this function will triggered.
      this.activeArticle =  this.entries.find(x=> x.fields.path === this.currentPath);
    });
  }

  getActivatedRoute(): string {
    return this.route.snapshot.paramMap.get("path");
  }

  /***
   * Model open
   */
  open(content) {
    this.modalService.open(content, { centered: true });
  }
  openLogin(content) {
    this.modalService.open(content, {
      size: "lg",
      windowClass: "modal-holder",
      centered: true,
    });
  }
  Subscribeopen(content) {
    this.modalService.open(content, {
      size: "lg",
      windowClass: "modal-holder",
      centered: true,
    });
  }
  Wishlistopen(content) {
    this.modalService.open(content, {
      size: "lg",
      windowClass: "modal-holder",
      centered: true,
    });
  }
  Emptyopen(content) {
    this.modalService.open(content, {
      size: "lg",
      windowClass: "modal-holder",
      centered: true,
    });
  }
}
